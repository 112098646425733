
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
// import moment from 'moment'
import AdminBrandForm from '@/views/Admin/Brands/components/AdminBrandForm.vue'
import Brands from '@/apis/Brands'
// import _ from 'lodash'
// import { UserStatusId } from '@/typings/AdminTyping'

@Component({
  name: 'addBrand',
  components: {
    AdminBrandForm
  }
}
)

export default class AddBrand extends Mixins(TableMixin) {
  simpleTable = true;
  brandId: any = null;
  brandDetails = {};

  storeBrand() {
    Brands.storeBrand(this.brandDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Brands' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
