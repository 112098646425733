
import Vue from 'vue'
import Component from 'vue-class-component'
import Axios from 'axios'
import {
  CarBrand,
  BrandDetails
} from '@/typings/AdminTyping'
import { Prop } from 'vue-property-decorator'
import Brands from '@/apis/Brands'

@Component({
  name: 'AdminBrandForm'
})

export default class AdminBrandForm extends Vue {
  @Prop(Object) BrandDetailsComp!: BrandDetails
}
